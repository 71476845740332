import { PageProps, graphql } from 'gatsby';
import Layout from 'layouts/Layout';
import LayoutSection from 'layouts/LayoutSection';
import { FC } from 'react';

import type { ContentfulTemplatePage } from 'graphqlTypes';

interface PageDataType {
  pageData: ContentfulTemplatePage;
}

const Page: FC<PageProps<PageDataType>> = ({
  data: {
    pageData: { seo, header, footer, sections },
  },
  location,
  pageContext: { industry },
}) => {
  if (!seo) {
    throw new Error(`Page at ${location.pathname} is missing SEO`);
  }

  return (
    <Layout slug={location.pathname} header={header} footer={footer} seo={seo}>
      {sections?.map(section => {
        if (section?.component?.__typename === 'ContentfulComponentHero') {
          if (section?.component?.heading) {
            section.component.heading = `${industry} Templates`;
          }
          if (section?.component?.body?.raw) {
            section.component.body.raw = `{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Practical ${industry} templates to help you run your field service business.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}`;
          }
        }
        if (section?.component?.__typename === 'ContentfulComponentSingleInstance') {
          if (location?.state?.stage) {
            section.component.stage = location.state.stage;
          }
        }

        return (
          <LayoutSection
            key={section.id}
            pageType={seo.pageType}
            displayBreadcrumbs={seo?.displayBreadcrumbs}
            {...section}
          />
        );
      })}
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query templateListingPageQuery($contentfulId: String!) {
    pageData: contentfulTemplatePage(contentful_id: { eq: $contentfulId }) {
      ...contentfulTemplatePage
    }
  }
`;
